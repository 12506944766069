.navbar {
  background-color: #002855; /* Custom dark blue background */
}

.navbar-brand img {
  height: 40px; /* Logo height */
}

@media (min-width: 1400px) {
  .container {
    max-width: 100% !important;
  }
}

/* @media (min-width: 1400px) {

          .container {
            max-width: 100%  !important;
          }
              } */
.navbar-text {
  color: #000 !important;
  font-size: 16px;
  font-weight: bold;
}

.navbar-toggler {
  border: none; /* Remove border from toggler */
}

/* The right-side drawer (hidden by default) */
.offcanvas-end {
  position: fixed;
  top: 0;
  right: -250px; /* Off-screen by default */
  height: 100%;
  width: 250px;
  background-color: #f8f9fa;
  transition: right 0.3s ease;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.offcanvas-end.open {
  right: 0; /* Slide in from the right */
}

/* Styling for the close button */
.close-btn {
  position: absolute;
  top: 20px;
  left: 10px;
  font-size: 30px;
  color: #000;
  background: transparent;
  border: none;
  cursor: pointer;
}

/* Menu Items styling */
.navbar-nav {
  padding-top: 50px; /* Adds padding to the top for better alignment */
}

.nav-item {
  list-style-type: none;
  padding: 10px;
  border-bottom: 1px solid #000;
}

.nav-link {
  text-decoration: none;
  font-size: 18px;
  color: #000;
  display: block;
}

/* Dropdown Menu Styling */
.nav-item.dropdown .dropdown-menu {
  width: 200px; /* Set a fixed width for dropdown */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add some shadow */
}

.nav-item.dropdown .dropdown-menu .dropdown-item {
  font-size: 16px;
  padding: 10px 15px;
}

/* Styling for the hamburger icon */
.navbar-toggler {
  /* border: 1px solid #000; */
  background: transparent;
  display: block;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0,0,0,.5)' stroke-width='2' d='M5 7h20M5 15h20M5 23h20'/%3E%3C/svg%3E");
  width: 30px;
  height: 30px;
  display: block;
}
