/* src/ImageSlider.css */
.slider-container {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
  }
  
  .slider {
    position: relative;
    width: 80%;
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .slider-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 18px;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }
  
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  