@media (max-width: 767px) {
  .navbar .logo img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}
/* Custom CSS for moving the caption */
.carousel-caption {
  position: absolute;
  top: 10px;  /* Adjust the top position */
  left: 10px; /* Adjust the left position */
  right: auto; /* Remove any right alignment */
  bottom: auto; /* Remove any bottom alignment */
  text-align: left; /* Align the text to the left */
}
